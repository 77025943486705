@import "./styles.scss";
@import "./reset.min.scss";
@import "~normalize.css";
* {
  &::selection {
    background-color: $black;
    color: $white;
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: $wh2;
  }
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: $wh2 transparent;
    scrollbar-width: thin;
  }
}

html,
body {
  min-height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  scroll-padding-top: 50px;
  scroll-behavior: smooth;
  position: relative;
}

body {
  color: $wh6;
  @include manrope5;
  // padding-right: 0 !important;
  overflow-x: hidden;
}

button {
  &:disabled {
    cursor: auto;
    pointer-events: none;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

svg {
  max-width: 100%;
  max-height: 100%;
}

#__next {
  overflow: hidden;
}

.MuiTooltip-popper {
  & > div {
    margin-bottom: 10px !important;
    padding: 0;
    background-color: transparent;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    transform: translateX(-20px) !important;
  }
}

.MuiSkeleton-root {
  animation: gradient 2s ease infinite;
  background: linear-gradient(90deg, #191919, #252424, #191919);
  background-size: 300% 100%;
}
@keyframes gradientInput {
  0% {
    background-position-x: 150%;
  }
  10% {
    background-position-x: 130%;
  }
  50% {
    background-position-x: 50%;
  }
  100% {
    background-position-x: 0%;
  }
}
@keyframes gradient {
  0% {
    background-position-x: 150%;
  }
  50% {
    background-position-x: 70%;
  }
  100% {
    background-position-x: 0%;
  }
}

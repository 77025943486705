// @font-face {
//   font-family: "Manrope";
//   src: url("Manrope-Bold.eot");
//   src: local("Manrope Bold"), local("Manrope-Bold"),
//     url("Manrope-Bold.eot?#iefix") format("embedded-opentype"),
//     url("Manrope-Bold.woff2") format("woff2"),
//     url("Manrope-Bold.woff") format("woff"),
//     url("Manrope-Bold.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Manrope";
//   src: url("Manrope-Semibold.eot");
//   src: local("Manrope Semibold"), local("Manrope-Semibold"),
//     url("Manrope-Semibold.eot?#iefix") format("embedded-opentype"),
//     url("Manrope-Semibold.woff2") format("woff2"),
//     url("Manrope-Semibold.woff") format("woff"),
//     url("Manrope-Semibold.ttf") format("truetype");
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Manrope";
//   src: url("Manrope-Regular.eot");
//   src: local("Manrope Regular"), local("Manrope-Regular"),
//     url("Manrope-Regular.eot?#iefix") format("embedded-opentype"),
//     url("Manrope-Regular.woff2") format("woff2"),
//     url("Manrope-Regular.woff") format("woff"),
//     url("Manrope-Regular.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Manrope";
//   src: url("Manrope-Medium.eot");
//   src: local("Manrope Medium"), local("Manrope-Medium"),
//     url("Manrope-Medium.eot?#iefix") format("embedded-opentype"),
//     url("Manrope-Medium.woff2") format("woff2"),
//     url("Manrope-Medium.woff") format("woff"),
//     url("Manrope-Medium.ttf") format("truetype");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Manrope";
//   src: url("Manrope-ExtraBold.eot");
//   src: local("Manrope ExtraBold"), local("Manrope-ExtraBold"),
//     url("Manrope-ExtraBold.eot?#iefix") format("embedded-opentype"),
//     url("Manrope-ExtraBold.woff2") format("woff2"),
//     url("Manrope-ExtraBold.woff") format("woff"),
//     url("Manrope-ExtraBold.ttf") format("truetype");
//   font-weight: 800;
//   font-style: normal;
//   font-display: swap;
// }

// @mixin manrope4 {
//   font-family: Manrope;
//   font-style: normal;
//   font-weight: 400;
// }
// @mixin manrope5 {
//   font-family: Manrope;
//   font-style: normal;
//   font-weight: 500;
// }
// @mixin manrope7 {
//   font-family: Manrope;
//   font-style: normal;
//   font-weight: 600;
// }

@font-face {
  font-family: "ArtelSans";
  src: local("ArtelSans Bold"), local("ArtelSans-Bold"),
    url("ArtelSans-Bold.woff2") format("woff2"),
    url("ArtelSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ArtelSans";
  src: local("ArtelSans Regular"), local("ArtelSans-Regular"),
    url("ArtelSans-Regular.woff2") format("woff2"),
    url("ArtelSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@mixin manrope4 {
  font-family: ArtelSans;
  font-style: normal;
  font-weight: 400;
}
@mixin manrope5 {
  font-family: ArtelSans;
  font-style: normal;
  font-weight: 400;
}
@mixin manrope7 {
  font-family: ArtelSans;
  font-style: normal;
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
hr,
menu,
nav,
section {
  display: block;
}
a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

input,
textarea {
  outline: none;
  border: none;
}
a,
hr {
  padding: 0;
}
button {
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
}
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}
strong {
  font-weight: bold;
}
ins,
mark {
  background-color: #ff9;
  color: #000;
}
body {
  line-height: 1;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
  -moz-osx-font-smoothing: grayscale;
}
nav ul,
ul {
  list-style: none;
  list-style-position: inside;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
ins {
  text-decoration: none;
}
mark {
  font-style: italic;
  font-weight: 700;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
}
input,
select {
  vertical-align: middle;
}
